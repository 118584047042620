import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import LoadScript from 'vue-plugin-load-script'
//import VS2 from 'vue-script2'

Vue.use(LoadScript);
//Vue.use(VS2)

export const bus = new Vue()



Vue.config.productionTip = false

new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')
