<template>
  <v-container>
    <v-row
    >
      <v-col
          cols="12"
          md="6"
      >
        <v-form ref="form">

            <template v-for="(item, i) in listFieldsActual"
            >

                <template v-if="item[1].TYPE === 'NAME'">
                    <v-text-field
                        v-model="item[1].DEFAULT_VALUE"
                        :label="item[1].NAME"
                        required
                    ></v-text-field>
                </template>

                <template v-if="item[1].TYPE === 'S' || item[1].TYPE === 'DETAIL_TEXT' || item[1].TYPE === 'S:Money' || item[1].TYPE === 'S:HTML'  || item[1].TYPE === 'N'">
                    <v-text-field
                        v-model="item[1].DEFAULT_VALUE"
                        :label="item[1].NAME"
                    ></v-text-field>
                </template>

                <!-- v-model=item[3]-->
                <template v-if="item[1].TYPE === 'E:EList'">
                    <v-select

                        :items= item[2]
                        :label="item[1].NAME"
                        @input="onInput($event, item[1].CODE)"
                    ></v-select>
                </template>

                <template v-if="item[1].TYPE === 'F'">
                    <v-file-input
                        accept="image/*"
                        :label="item[1].NAME"
                        :id= "item[1].CODE !== null > 0 ? item[1].CODE : item[0]"
                    ></v-file-input>
                </template>

                <template v-if="item[1].TYPE === 'PREVIEW_PICTURE'">
                    <v-file-input
                        accept="image/*"
                        :label="item[1].NAME"
                        :id= "'___' + item[0]"
                    ></v-file-input>
                </template>


                <template v-if="item[1].TYPE === 'E'">
                    <v-autocomplete
                        :items=item[2]
                        dense
                        :label="item[1].NAME"
                        @input="onInput($event, item[1].CODE)"
                    ></v-autocomplete>
                </template>

                <template v-if="item[1].TYPE === 'S:Date'">
                    <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="item[1].DEFAULT_VALUE"
                                :label="item[1].NAME"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="item[1].DEFAULT_VALUE"
                            @input="menu2 = false"
                        ></v-date-picker>
                    </v-menu>
                </template>

                <template v-if="item[1].TYPE === 'S:employee'">
                    <v-autocomplete
                        :items="userListNamesOnlyArray"
                        dense
                        :label="item[1].NAME"
                        @input="onInput($event, item[1].CODE)"
                    ></v-autocomplete>
                </template>

            </template>

<!--          <v-text-field
              v-model="testCode"
              label="Name"
          ></v-text-field>

          <v-autocomplete
              v-model="value"
              :items="itemsAutocomplete"
              dense
              label="Autocomplete (users)"
          ></v-autocomplete>

          <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="date"
                  label="Picker without buttons"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="date"
                @input="menu2 = false"
            ></v-date-picker>
          </v-menu>

          <v-file-input
              accept="image/*"
              label="File input"
          ></v-file-input>

          <v-select
              :items="items"
              label="Filled style"
          ></v-select>

          <v-checkbox
              v-model="checkbox"
              :label="`Checkbox 1: ${checkbox.toString()}`"
          ></v-checkbox>

          <v-switch
              v-model="switch1"
              :label="`Switch 1: ${switch1.toString()}`"
          ></v-switch>

          <v-textarea
              name="input-7-1"
              label="Default style"
              value="The Woodman set to work at once, and so sharp was his axe that the tree was soon chopped nearly through."
              hint="Hint text"
          ></v-textarea>

          <v-radio-group v-model="radioGroup">
            <v-radio
                v-for="n in 3"
                :key="n"
                :label="`Radio ${n}`"
                :value="n"
            ></v-radio>
          </v-radio-group>-->

        </v-form>

      </v-col>
    </v-row>
  </v-container>
</template>




<script>
export default {

    name: 'NewForm',

    props: {
        listFields: Array,
        userList: Array
    },

  data: () => ({
    allowSpaces: false,
    match: 'Foobar',
    max: 0,
    model: 'Foobar',

    items: ['Foo', 'Bar', 'Fizz', 'Buzz'],
    switch1: true,
    radioGroup: 1,
    checkbox: true,

    itemsAutocomplete: ['foo', 'bar', 'fizz', 'buzz'],
    values: ['foo', 'bar'],
    value: [],
    valueIBlockLink: [],


    date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    menu: false,
    modal: false,
    menu2: false,

  }),

    mounted() {
    },

    computed: {
        listFieldsActual: function () {
            let fieldValues = []
            this.listFields.forEach( listField => {
                if(listField[1].DISPLAY_VALUES_FORM) {
                    let formValues = Object.entries(listField[1].DISPLAY_VALUES_FORM)
                    listField.push(formValues)

/*
                    let formValues = Object.values(listField[1].DISPLAY_VALUES_FORM)
                    listField.push(formValues)

                    let formCodes = Object.keys(listField[1].DISPLAY_VALUES_FORM)
                    listField.push(formCodes)
*/

                }
            })

            console.log("listFields from computed prop listFieldsActual:")
            console.log(this.listFields)

            return this.listFields;

        },
        testCode: function () {
            return this.listFields[0][1].IBLOCK_ID
        },
        userListNamesOnlyArray: function () {
            let userNames = []
            this.userList.forEach( user => {

                userNames.push(user[1].NAME + " " + user[1].LAST_NAME)
            })
            return userNames;
        }
    },

    methods: {
        onInput(selected, fromWhere) {

            console.log("selected:")
            console.log(selected)
            console.log("fromWhere:")
            console.log(fromWhere)

            console.log(`selected: ${selected}. fromWhere: ${fromWhere}`)

            this.listFields.forEach( listField => {
                if(listField[1].CODE === fromWhere) {
                    listField[1].USER_TYPE_SETTINGS = selected
                }
            })
        }
    }




}
</script>


