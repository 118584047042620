<template>
  <v-app>
<!--
    <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
      </div>

      <v-spacer></v-spacer>

      <v-btn
        href="#"
        target="_blank"
        text
      >
        <span class="mr-2">FOT WebForms App</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar>
-->

    <v-main>

      <WebFormList/>

    </v-main>
  </v-app>
</template>


<script>
import WebFormList from './components/WebFormList';

export default {
  name: 'App',

  components: {
    WebFormList,
  },

  data: () => ({
    //
  }),
};
</script>
